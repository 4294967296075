<template>
  <!--
    Used to edit the StartFinish OperationMode
  -->
  <div class="operationMode">
    <template v-if="operationMode">
      <form>
        <div class="form-group">
          <h4 class="m-0 mt-3">
            OperationMode
          </h4>
          <hr class="m-0 mb-3">
          <div class="btn-group">
            <button
              :disabled="operationMode == 'trackerFullMode'"
              class="btn btn-primary"
              @click.prevent="changeOperationMode('trackerFullMode')"
            >
              Full Mode
            </button>
            <button
              :disabled="operationMode == 'trackerTestMode'"
              class="btn btn-primary"
              @click.prevent="changeOperationMode('trackerTestMode')"
            >
              Test Mode
            </button>
            <button
              :disabled="operationMode == 'timingMode'"
              class="btn btn-primary"
              @click.prevent="changeOperationMode('timingMode')"
            >
              Timing Mode
            </button>
          </div>
        </div>
        <div class="clearfix" />
      </form>
    </template>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "OperationModeEdit",
  mixins: [
    errorMixin
  ],
  props: {
    operationMode: {
      type: String,
      required: true
    },
    installationId: {
      type: String,
      required: true
    },
    laneNumber: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  computed: {
    computedValue: {
    get: function () {
        return this.operationMode;
    },
    set: function () {},
    }
  },
  methods: {
    changeOperationMode (mode) {
      let url = `/StartFinish/ChangeOperationMode?installationId=${ this.installationId }&operationMode=${ mode }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`
      }
      this.axios.post(url)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.$emit('close');
        });
    }
  }
}
</script>
